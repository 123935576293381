html, body {
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.container {
    flex: 1;
}

.footer {
    background: #16105A;
    color: white;
    position: relative;
    bottom: 0;
    width: 100%;
}