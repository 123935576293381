.about-us-text {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 250px;
  font-size: 9px;
}

.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 9px;
}
