.factory {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  margin-top: -60px;
}

.poor {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -60px;
}

.manifesto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  font-size: 9px;
}
