.cell-layout {
  display: flex;
  justify-content: center;
  max-width: 800px !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(150px, auto);
  gap: 16px;
}

.text-content {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  padding: 16px;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}

.grid-item {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Element that spans across two columns */
.grid-item-span {
  grid-column: span 2; /* Spans across two columns */
}

/* Responsive adjustments */
@media (max-width: 1400px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 850px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .grid-item-span {
    grid-column: span 1; /* Spans across one column */
  }
}
