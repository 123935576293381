.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image {
  width: 100%;
  height: auto;
}

p {
  margin-bottom: 2px !important;
  text-align: center;
}

.cursive {
  font-family: "Kavivanar", cursive;
}
