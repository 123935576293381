.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  font-family: "Jomolhari", serif;
}

.header {
  font-family: Jomolhari, serif;
  font-size: 100px;
  font-weight: 400;
  color: "#CCCD9B";
}

.subtitle {
  color: #4c7f40;
  font-family: "Irish Grover", system-ui !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.small-tile {
  width: 186px;
  height: 186px;
  top: 217px;
  left: 547px;
  gap: 0px;
  border-radius: 70px 0px 0px 0px;
  border: 2px 0px 0px 0px;
  opacity: 0px;
}

.tile {
  color: #cccd9b;
  text-align: center;
  font-family: "Jomhuria", serif;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.section-title {
  color: #cccd9b;
  text-align: center;
  font-family: "Jomhuria", serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 100px;
}

.tile-title {
  font-size: 50px;
  margin-top: 8px;
}

.tile-subtitle {
  font-size: 25px;
  margin-top: -10px;
}

.tile-image {
  width: 100px;
  height: 100px;
  margin-top: 10px;
  object-fit: contain;
}
