.cell {
  width: 186px;
  height: 186px;
  flex-shrink: 0;
  gap: 0px;
  border-radius: 70px;
  border: 2px solid #000;
  background: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.cell:hover {
  background: #f0f0f0;
  cursor: pointer;
}
