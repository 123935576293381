/* src/App.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.title-container {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: "Times New Roman", serif;
  font-size: 48px;
  font-weight: bold;
}

.subtitle {
  font-family: "Times New Roman", serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.custom-button {
  background-color: transparent;
  border: transparent;
  padding: 10px 20px;
  border-radius: 20px;
  font-family: "Times New Roman", serif;
  font-size: 14px;
}
