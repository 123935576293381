.artist-image {
  object-fit: cover;
  border-radius: 25px;
  border-width: 3px;
  border-color: black;
  border-style: solid;
}

.artist:hover {
  cursor: pointer;
}

.keywords {
  font-size: 25px !important;
  font-weight: 500 !important;
}

.artist {
  margin: 25px;
}
