.footer {
  color: white;
  text-align: left;
  left: 0;
  bottom: 0;
}

.footer h2 {
  font-size: 2em;
  margin: 0;
  font-weight: 700;
}

.footer-link {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .footer h2 {
    font-size: 1.2em;
  }
}
